import { merge } from "lodash";
import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import { fNumber } from "../../../utils/formatNumber";
import { BaseOptionChart } from "../../charts";

function AppConversionRates(props) {
  const [refreshGraph, setRefreshGraph] = useState(false);
  const [mappedData, setMappedData] = useState([]);
  const [combinedMappedData, setCombinedMappData] = useState([]);

  useEffect(() => {
    if (mappedData.length > 0) {
      const sortedMappedData = [...mappedData].sort((a, b) => b.y[0] - a.y[0]);
      const seriesData = [
        {
          name: "Your Practice",
          data: sortedMappedData?.map((item) => ({
            x: item.x,
            y: item.y[0],
            fillColor: item.fillColor,
          })),
        },
        {
          name: "Filtered Practice",
          data: sortedMappedData?.map((item) => ({
            x: item.x,
            y: item.y[1],
            fillColor: item.strokeColor,
          })),
        },
      ];

      setCombinedMappData(seriesData);
      setRefreshGraph((prev) => !prev);
    }
  }, [mappedData]);

  useEffect(() => {
    const combineData = (data1, data2) => {
      const map = new Map();
      data1?.forEach((item) => {
        map.set(item.x, { ...item });
      });
      data2?.forEach((item) => {
        if (map.has(item.x)) {
          const existingItem = map.get(item.x);
          map.set(item.x, {
            ...existingItem,
            y: [existingItem.y, item.y],
          });
        } else {
          map.set(item.x, { ...item });
        }
      });

      return Array.from(map.values());
    };

    const combinedGreenData = combineData(
      props.ab?.green_resistance?.length ? props.ab.green_resistance : [],
      props.otherab?.othertypes_resistance_green?.length
        ? props.otherab.othertypes_resistance_green
        : []
    );

    const combinedRedData = combineData(
      props.ab?.red_resistance?.length ? props.ab.red_resistance : [],
      props.otherab?.othertypes_resistance_red?.length
        ? props.otherab.othertypes_resistance_red
        : []
    );

    const finalCombinedData = [...combinedGreenData, ...combinedRedData];

    setMappedData(finalCombinedData);
  }, [props.ab, props.otherab]);

  const chartOptionsResist = merge(BaseOptionChart(), {
    tooltip: {
      enabled: false,
      marker: { show: false },
      x: {
        show: false,
        // format: 'dd MMM',
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      y: {
        formatter: (value) => {
          if (props.ab.resistanceTotal) {
            const actualCount = (
              (value * props.ab.resistanceTotal) /
              100
            ).toFixed(0);
            return fNumber(actualCount);
          }
        },
        title: {
          formatter: (seriesName) => `Count of isolates showing resistance: `,
        },
      },
    },
    chart: {
      type: "bar",
      height: 350,
      stacked: false,
      stackType: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "top",
        },
        barHeight: 20,
        borderRadiusApplication: "end",
      },
    },
    stroke: {
      show: false,
    },
    dataLabels: {
      formatter: function (value, { seriesIndex, dataPointIndex, w }) {
        return (value ? value.toFixed(0) : 0) + "%";
      },
      enabled: true,
      offsetX: 90,
      style: {
        colors: ["#708090"],
        fontWeight: "normal",
      },
      // offsetX: 50,
    },
    xaxis: {
      min: 0,
      max: 100,
      stepSize: 25,
      title: {
        text: "%",
        style: {
          color: "#22577A",
        },
      },
      labels: {
        show: true,
        style: {
          colors: "#22577A",
        },
      },
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          colors: "#22577A",
        },
      },
    },
    fill: {
      opacity: [1, 0.5],
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "right",
      floating: true,
      markers: {
        width: 0,
        height: 0,
      },
    },
  });

  return (
    <Card sx={{ boxShadow: "none" }}>
      <Typography variant="h6" color="#22577a">
        {" "}
        Breakdown of antibiotic resistance.
      </Typography>
      <Stack
        direction="row"
        justifyContent="space-evenly"
        alignItems="flex-end"
        spacing={6}
        mt={1}
      >
        <Grid container>
          <Grid item sm={12} md={6}>
            <Box
              sx={{ mx: 1, width: 700, height: 500, marginLeft: "11%" }}
              dir="ltr"
            >
              <br />
              <ReactApexChart
                type="bar"
                series={combinedMappedData}
                options={chartOptionsResist}
                key={refreshGraph}
              />
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </Card>
  );
}

import { connect } from "react-redux";
const mapState = (state) => ({
  ab: state.antibiotics.ab,
  otherab: state.antibioticscomparisons.abcomparison,
});

const mapDispatch = ({ antibiotics: { getGeneralAB } }) => ({
  getGeneralAB: () => getGeneralAB(),
});

export default connect(mapState, mapDispatch)(AppConversionRates);
